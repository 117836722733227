<template>
  <div class="main">
    <div class="main-center">
      <div class="top">
        <img class="logo" src="@/assets/logo.png">
        <!-- 导航 -->
        <ul class="top-menu">
          <li><a href="#"></a></li>
          <li><a href="#"></a></li>
          <li v-for="nav in navList" :key="nav.id"  :class="{ liActive: activeLink == nav.slug }" @click="go(nav.slug)">
              {{ nav.name }}
          </li>
        </ul>

        <!-- 用户图标 -->
        <div class="user">
          <el-avatar icon="el-icon-user-solid"></el-avatar>
        </div>
      </div>
    </div>

    <div class="content">
      <h1>{{ content.title }}</h1>
      <h3>{{ content.subtitle }}</h3>
      <h4>{{ content.updateTime }}       {{ content.author }}  </h4>
      <p><img :src='content.cover'/></p>
      <p class="nei" v-html="content.content"> </p>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <div class="footer-center">
        <table width="100%" height="100%">
          <tr>
            <td rowspan="2" width="13%" style="text-align:center">
              <img src="@/assets/logo.png" width="80%">
              <h3 style="text-align:center;color:white;">微信公众号</h3>
            </td>
            <td rowspan="2" width="13%" style="text-align:center">
              <img src="@/assets/logo.png" width="80%">
              <h3 style="text-align:center;color:white;">抖音二维码</h3>
            </td>
            <td width="13%" height="50%" style="text-align:center;vertical-align: text-top;">
              <h3 style="text-align:center;color:white;">地方协会</h3>
              <span style="color:red;">LOCAL ASSOCIATIONS</span>
              </td>
            <td>
              <ul class="hui" style="color:white;">
                <li>武汉</li>
                <li>延边</li>
                <li>成都</li>
                <li>上海</li>
                <li>西安</li>
                <li>杭州</li>
                <li>深圳</li>
                <li>乌鲁木齐</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td width="13%" style="text-align:center; vertical-align: text-top;">
              <h3 style="text-align:center;color:white;">友情链接</h3>
              <span style="color:red;">FRIENDSHIP LINK</span>
              </td>
            <td>
              <ul class="hui" style="color:darkgray;">
                <li>MMA官网</li>
                <li>中国格斗协会</li>
                <li>中国武术协会</li>
                <li>武林风</li>
                <li>UFC</li>
                <li>昆仑决</li>
                <li>运动员联盟</li>
                <li>中国体育</li>
              </ul>
            </td>
          </tr>
        </table>
         <div style="text-align:center;color:darkgray;  background-color: #181818;">
              &copy;2024星云恒宇（北京）国际体育科技发展有限公司 版权所有 <span style="color:white;">京ICP证9999999号</span>
            </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components:{

  },
  props: {
  },
  data() {
    return{
      navList:[],
      contentId:null,
      content: null,
      activeLink: null,
    };
  },
  mounted(){
   
  },
  created(){
    this.activeLink = this.$route.query.link  ;
    this.contentId = this.$route.query.contentId  ;
    // 初始化页面数据
    this.init();
  },
  methods: {
    init(){
      // 加载导航
      axios.get('/cms/front/channelList?siteId=2')
        .then(response => {
          this.navList = response.data.data;
          // console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
      // 获取内容详情
      axios.get('/cms/front/getContentById?contentId='+ this.contentId)
        .then(response => {
          this.content = response.data.data;
          if (this.content && this.content.content) {
            // 创建一个临时的div来解析HTML
            const div = document.createElement('div');
            div.innerHTML = this.content.content;
            
            // 找到所有图片并移除它们的宽度和高度属性
            const images = div.getElementsByTagName('img');
            for (let img of images) {
              img.removeAttribute('width');
              img.removeAttribute('height');
              img.removeAttribute('style');
            }
            
            // 更新处理后的内容
            this.content.content = div.innerHTML;
          }
          console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });  
    },
    go(link){
      if(link == null || link == ''){
        this.$router.push("/");
      }else{
        this.$router.push({
          path: link,
          query: {'link': link}
        });
      }
    },
  }
}
</script>

<style scoped>
.main{
  background-color: black;
  min-height: 920px;
  height: 1400px;
  font-size: 12px; 
  /* border:1px solid red; */
}
ul,li{
  margin:0px;
  padding:0px;
}
.top-menu li:hover{
  cursor: pointer;
  color: aliceblue;
}

.liActive{
  font-weight: bold;
  color: honeydew;
}
.main-center{
  width: 76%;
  margin: 0px auto;
}
.top{
  border:1px solid black;
  height: 80px;;
  background-color: black;
}
.logo{
  width: 128px;
  height: 68px;
  float: left;
}
.top-menu {
  width: 600px;
  height: 40px;
  font-size: 12px;
  background-color: red;
  border-radius: 6px;
  color: black;
  float: left;
  margin-left: 30px;;
  margin-top: 30px;
}
.top-menu li{
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
}
a{
  text-decoration: none;
}
.top-menu-right img{
  height: 46px;
  float: right;
  position: relative;
  top:-3px;
  left: 40px;;
}
.top-menu ul {
  list-style-type: none;
  padding: 0;
}
.top-menu li {
  display: inline-block;
  margin: 0 10px;
}

.user{
  float: right;
  margin-top:30px;
  margin-right: 20px;
}

.footer{
  width: 100%;
  height: 320px;
  margin-top:20px;
  /* border:1px solid black; */
  float: left;
  clear: both;
  background-color: #181818;
}

.footer-center{
  width: 80%;
  height: 300px;
  margin: 0px auto;
  /* border:1px solid black; */
}
.footer-center ul{
  padding:0px;
  margin: 0px;
}
.footer-center li{
  float: left;
  height: 30px;
  width: 260px;
  text-align: center;
  list-style: none;
}

.content{
  width: 80%;
  min-height: 450px;
  height: auto;
  background-color: #181818;
  margin-top: 30px;
  padding-top:30px;
  margin:0px auto;
  color: white;
}
.content h1,h3,h4,p{
  text-align: center;
}
.content .nei{
  width: 90%;
  min-width: 700px;
  margin:0px auto;
  /* border:1px solid red; */
}
.content .nei img,
.content .nei >>> img,
.content .nei ::v-deep img {
  max-width: 100% !important;
  height: auto !important;
  width: auto !important;
  object-fit: contain !important;
}
.content img{
  max-width: 600px;
}
</style>
