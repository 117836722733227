/**
 * 清理HTML内容，移除HTML标签和样式
 * @param {string} html HTML内容
 * @param {number} length 需要截取的长度
 * @returns {string} 清理后的纯文本
 */
export function cleanHtml(html, length) {
  if (!html) return '';
  
  // 创建临时DOM元素
  let temp = document.createElement('div');
  temp.innerHTML = html;
  
  // 获取纯文本内容
  let text = temp.textContent || temp.innerText;
  
  // 截取指定长度
  if (length && text.length > length) {
    text = text.substring(0, length) + '...';
  }
  
  return text;
} 